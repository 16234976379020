.c-testimonial {
        background-color: #EDEDED;
        &-card {
        padding-top: 28px;
        padding-left: 29px;
        padding-right: 32px;
        padding-bottom: 39px; 
        box-shadow: 0 35px 31px -1px rgba(0,0,0,0.09);
        border-radius: 6px;
        background: #fff;
        &__info {
            padding-left: 32px;
            font-size: 16px;
            line-height: 19px;
        }
        &__img {
            width: 82px;
            height: 82px;
            object-fit: cover;
            border-radius: 50%;
            background-color: #fff;
        }

    }
}