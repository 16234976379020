.c-testimonials {
  padding-left: calc((100% - 1200px)/2);
  padding-top: 0;
  padding-bottom: 0;
  z-index: 5;
  position: relative;
  @media (max-width: 1220px) {
    padding-left: 20px;
  }
  @media (max-width: 519px) {
    padding-left: 0;
    padding-top: 70px;
  }

  &__item {
    box-shadow: $global-shadow;
    padding: $inuit-global-spacing-unit-huge $inuit-global-spacing-unit-massive;
    .wp-block-quote {
      padding-left: $inuit-global-spacing-unit-huge +
        $inuit-global-spacing-unit-big;
      margin: $inuit-global-spacing-unit-big (-$inuit-global-spacing-unit-small)
        0;
      p {
        @include inuit-font-size($inuit-font-size-h4, $important: true);
      }
    }
  }
  &__author {
    font-weight: $global-strong-font-weight;
    text-align: right;
    margin-top: $inuit-global-spacing-unit-tiny !important;
  }


  .ti-widget.ti-goog .ti-col-5 .ti-review-item {
    flex: 0 0 40%!important;
    max-width: 40%!important;
  }

  .ti-widget.ti-goog .ti-col-4 .ti-review-item {
    flex: 0 0 40%!important;
    max-width: 40%!important;
  }
  .ti-widget.ti-goog .ti-reviews-container-wrapper [class$=inner]>.ti-stars {
    display: none;
  }

  .ti-widget.ti-goog .ti-widget-container .ti-date {
    display: none;
  }  

  .ti-widget.ti-goog .ti-large-logo {
    display: none;
  }

  .ti-widget.ti-goog .ti-review-item > .ti-inner {
    box-shadow: 0 35px 31px -1px rgba(0,0,0,0.09) !important;
    padding-bottom: 40px!important;
    padding-top: 28px!important;
    padding-left: 32px!important;
    padding-right: 32px!important;
  }
  .ti-widget.ti-goog .ti-reviews-container-wrapper {
    padding-top: 75px!important;
    padding-bottom: 60px!important;
    @media (max-width: 519px) {
        padding-top: 20px!important;
    }
  }

  .ti-widget.ti-goog .ti-review-header {
    margin-bottom: 20px;
  }

  .ti-widget.ti-goog .star-lg .ti-star {
    width: 24px;
    height: 24px;
  }

  .ti-widget.ti-goog .ti-footer .ti-stars {
    
  }
  .ti-widget.ti-goog .ti-rating-text {
    text-align: left;
    @media (max-width: 519px) {
      text-align: center;
    }
  }

  .ti-widget.ti-goog .ti-rating-text strong.ti-rating {
    display: none;
  }

  .ti-widget.ti-goog .nowrap:last-of-type {
    color: transparent;
    transform: translateX(-88px);
    @media (max-width: 519px) {
      text-align: center;
      transform: translateX(-44px);
    }
  }

   .ti-widget.ti-goog .ti-footer .ti-stars {
    text-align: left;
    display: block;
    position: relative;
    @media (max-width: 519px) {
      text-align: center;
    }
    &::before {
      content: '4,8';
      position: relative;
      color: #000;
      font-size: 30px;
      font-weight: 700;
      left: 0;
      top: 0;
      padding-right: 5px;
      font-family: "Space Grotesk";
    }
  }

  .ti-widget.ti-goog  .ti-footer.source-Google {
    position: relative;
    &::before {
      content: 'Opinie z Google';
      font-family: "Space Grotesk";
      font-size: 30px;
      font-weight: bold;
      top: -40px;
      left: 0;
      position: absolute;
      width: 100%;
      text-align: left;
      @media (max-width: 519px) {
        text-align: center;
      }
    }
  }

  .ti-widget.ti-goog .ti-review-item {
    padding: 0 15px !important;
  }

  .ti-widget.ti-goog .ti-widget-container {
    margin-bottom: 0;
  }

  .ti-widget.ti-goog .ti-controls-dots {
    display: block!important;
    padding-bottom: 40px!important;
    @media (max-width: 519px) {
      padding-bottom: 20px!important;
    }
    .dot {
      width: 40px !important;
      height: 6px !important;
      border-radius: 5.5px !important;
      background-color: Gray !important;
      opacity: 0.3;
    }

  }
  .ti-widget.ti-goog .ti-col-1 .ti-reviews-container, .ti-widget.ti-goog .ti-col-1 .ti-footer {
    @media (max-width: 519px) {
      margin-bottom: 0!important;
    }
  }



}
