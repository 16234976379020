.c-slider {
    &__title {
        margin-bottom: 45px;
        @media (max-width: 768px) {
            margin-bottom: 30px;
        }
    }
    &__similarcars {
        background: #141414;
        z-index: 10;
        position: relative;
    }
    &__testimonials {
        z-index: 10;
        position: relative;
        background-color: #EDEDED;
    }
    &__categories {
        z-index: 10;
        position: relative;
        &--text {
            font-family: $global-heading-font-family;
            font-size: 18px;
        }
        .swiper-slide {
            .c-categories__card {
                height: 380px;
                width: auto;
            }
            .c-categories__card--wrap:nth-child(odd) {
                transform: translateY(0);
            }

        }
    }
}