
.c-infocards {
    &__card {
        border-radius: 10px;
        box-shadow: 0 24px 58px 0 rgba(0,0,0,0.09);
        padding: 28px 40px;
        background: #fff;
        height: 100%;
    }
    &__icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-bottom: 10px;
    }
    &__text {
        color: #515151;
        font-size: 20px;
        line-height: 27px;
        min-height: 100px;
        display: flex;
        align-items: center;
    }
    &__row {
        align-items: initial;
    }
}