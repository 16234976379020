.embed-calendar {
    .rcs-calendar-table > thead > .rcs-calendar-thead-controls > th > a {
        background: transparent;
        color: #000;
    }
    .rcs-calendar-table > thead > .rcs-calendar-thead-controls > th:first-of-type > a {
        color: transparent;
        height: 32px;
        display: inline-block;
        position: relative;
        width: 20px;
        margin-left: 10px;
        &:before,
        &:after {
          content:"";
          position: absolute;
          background: #000;
          border-radius: 0.2rem;
          display: block;
          left: -5%;
          height: 3px;
          width: 11px;
          transform: rotate(45deg);
        }
        &:after {
            bottom: 55%;
            transform: rotate(-45deg);
            
        }
        &:before {
            top: 55%;
            
        }
          &:hover {
            opacity: 0.7;
        }

    }
    .rcs-calendar-table > thead > .rcs-calendar-thead-controls > th:last-of-type > a {
        color: transparent;
        height: 32px;
        display: inline-block;
        position: relative;
        width: 20px;
        margin-right: 10px;
        &:before,
        &:after {
          content:"";
          position: absolute;
          background: #000;
          border-radius: 0.2rem;
          display: block;
          left: -5%;
          height: 3px;
          width: 11px;
          transform: rotate(-45deg);
        }
        &:after {
            bottom: 55%;
            transform: rotate(45deg);
        }
        &:before {
            top: 55%;
        }
        &:hover {
            opacity: 0.7;
        }

    }
    .rcs-calendar-table > tbody > tr > td.rcs-calendar-prev-month {
        background: transparent;
        color: #868585;
        font-weight: 400;
    }
    .rcs-calendar-table > tbody > tr > td.rcs-calendar-unavailable {
        background: transparent;
        color: #C00505;
    }
    .rcs-calendar-table > tbody > tr > td {
        background: transparent;
        color: #378E00;
        font-weight: 700;
        font-family: "Roboto", arial;
        border-radius: 10px;
        &:hover {
            border-radius: 10px;
        }
    }

    .rcs-calendar-table > tbody > tr > td.rcs-calendar-available {
        &:hover {
            background-color: color-var(main, 1);
            color: #000;
        }
    }
    .rcs-calendar-table > tfoot > tr.rcs-calendar-tfoot-legend > td > p:first-of-type > span {
        background: #378E00;
    }
    .rcs-calendar-table > tfoot > tr.rcs-calendar-tfoot-legend > td > p:last-of-type > span {
        background: #C00505;
    }
    .rcs-calendar-table > tbody > tr > td.rcs-calendar-selected {
        background-color: color-var(main, 1);
        color: #000;
        border-radius: 0;
        border-radius: 10px 0 0 10px;
        ~ td.rcs-calendar-selected {
            border-radius: 0;
            &:last-of-type {
                    border-radius: 0 10px 10px 0;
            }
        } 
    }
    .rcs-calendar-table > tbody > tr > td {
        border: 0;
    }
    .rcs-calendar-table > thead > .rcs-calendar-thead-days > th {
        font-size: 14px;
        color: #868585;
        padding-bottom: 20px;
    }
    .rcs-calendar-table > tbody > tr > td.rcs-calendar-next-month {
        background: transparent;
        color: #868585;
        font-weight: 400;
    }
    .rcs-calendar-table > tfoot > tr.rcs-calendar-tfoot-legend > td {
        padding-top: 40px;
    }
    .rcs-calendar-table > thead > .rcs-calendar-thead-controls > th {
        font-family: 'Roboto';
        font-size: 18px;
        font-weight: 700;
        padding-bottom: 30px;
    }

    .rcs-calendar-thead-title {
        display: none;
    }
    .rcs-calendar-table>tfoot>tr.rcs-calendar-tfoot-summary-btn>td>form>button {
        background-color: color-var(main, 1);
        border: 1px solid color-var(main, 1);
        font-weight: 600;
        &:hover {
            background-color: transparent;
            color: #000;
        }
    }
   .rcs-calendar-table > tfoot > tr.rcs-calendar-tfoot-summary-btn > td > form > button {
    //    display: none!important;
   }
   .rcs-calendar-tfoot-legend p {
       font-size: 12px;
       padding-left: 7px;
   }
   .rcs-calendar-tfoot-legend p span {
    margin-right: 3px;
    }
    .rcs-calendar-tfoot-summary td strong.rcs-calendar-tfoot-summary-date-price {
        font-size: 20px;
        color: #000;
        font-weight: 700;
    }
    .rcs-calendar-tfoot-summary td strong.rcs-calendar-tfoot-summary-date-price + strong {
        font-size: 20px;
        color: #000;
        font-weight: 700;
    }

    .rcs-calendar-tfoot-summary td {
        font-size: 12px!important;
        color: #525252;
    }
    .rcs-calendar-tfoot-summary td strong {
        font-weight: normal;
    }
}
