.c-specification {
    position: relative;
    background-color: #1B1B1B;
    border-radius: 10px;
    padding-top: 25px;
    padding-left: 50px;
    padding-bottom: 35px;
    padding-right: 75px;
    box-shadow: 0 24px 58px 0 rgba(0,0,0,0.24);
    z-index: 10;
    @media (max-width: 768px) {
        border-radius: 0;
        padding: 30px;
        margin-left: -16px;
        margin-right: -16px;
    }
    &__title {
        color: #fff;
        line-height: 1;
        margin-bottom: 15px;
        font-size: 30px;
        @media (max-width: 768px) {
            text-align: center;
            font-size: 24px;
            line-height: 30px;
        }
        &--wrap {
            justify-content: space-between;
            padding-right: 37px;
        }
    }
    &__logo {
        position: absolute;
        width: 80px;
        height: 80px;
        top: 17px;
        right: 37px;
        @media (max-width: 768px) {
           position: relative;
           margin-left: auto;
           margin-right: auto;
           display: block;
           top: initial;
           right: initial;
        }
    }
    &__text {
        color: #525252;
        font-size: 20px;
        font-family: $global-heading-font-family;
        margin-bottom: 25px;
        font-weight: 700;
        @media (max-width: 768px) {
            text-align: center;
        }
    }
    &__value {
        color: color-var(main, 1);
        font-size: 26px;
        font-weight: 700;
        font-family: $global-heading-font-family;
        margin-bottom: 0;
        @media (max-width: 768px) {
            font-size: 21px;
            line-height: 28px;
        }

    }
    &__name {
        font-size: 18px;
        font-family: $global-heading-font-family;
        color: #e7e7e7;
        margin-bottom: 0;
        @media (max-width: 768px) {
            font-size: 15px;
            line-height: 20px;
        }
    }
}