.c-post-list {
  &__title {
    margin-bottom: 0;
  }
  &__content {
    margin-top: $inuit-global-spacing-unit-huge;
  }
  &__cta {
    margin-top: $inuit-global-spacing-unit-huge;
  }
  &--reversed {
    margin-top: -$inuit-global-spacing-unit-huge * 6;
  }
  &--bordered {
    .c-tease,
    .c-article {
      box-shadow: $global-shadow;
      background: color-var(bg);
      color: color-var(font) !important;
      &.c-tease,
      &.c-article,
      &.c-tease *,
      &.c-article * {
        color: color-var(font) !important;
      }
      .c-tag {
        color: color-var(font, 3) !important;

        &--alternative {
          color: color-var(font, 2) !important;
        }
      }
    }
  }
  &__item {
    margin-top: $inuit-global-spacing-unit-great;
    .c-article {
      height: 100%;
    }
  }
}
