.wp-block-quote {
  // border: $global-border solid color-var(border);
  padding: $inuit-global-spacing-unit-large;
  padding-left: 77px;
  padding-right: 90px;
  line-height: 27px;
  font-size: 20px;
  color: #000;
  font-weight: 700;
  font-style: normal;
  font-family: $global-heading-font-family;

  @media(max-width: 768px) {
    padding-left: 45px;
    padding-right: 0;
  }

  &.is-style-large {
    border: 0;
    padding: 0;
    padding-left: $inuit-global-spacing-unit-massive;
    color: color-var(font);
    @include inuit-font-size($inuit-font-size-h3, $inuit-line-height-h3);
    background: url(../assets/images/svg/quote.svg) no-repeat top
      $inuit-global-spacing-unit-tiny left;
  }
  p {
    margin: 0;
    position: relative;
    &:before {
      // display: none;
      position: absolute;
      color: transparent;
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 80px;
      transform: translate(-120%, -6px);
      height: 80px;
      width: 40px;
      line-height: 80px;

    }
  }
}
.wp-block {
  &[data-type^='acf'] {
    margin-top: 0;
    margin-bottom: 0;
  }

  &:not([data-type^='acf']) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      position: relative;
      font-weight: $global-heading-font-weight;
      font-family: $global-heading-font-family;
      margin-bottom: $inuit-global-spacing-unit;
      z-index: 1;
      @include inuit-font-size($inuit-font-size-h3, $inuit-line-height-h3);
    }

    img {
      margin-bottom: $inuit-global-spacing-unit-great;
    }

    ul,
    ol {
      padding-left: $inuit-global-spacing-unit-great;
      list-style: none;
      counter-reset: list;
      margin: 0;
      margin-bottom: $inuit-global-spacing-unit-great;
      line-height: 2.25em;

      li {
        position: relative;
      }

      li:before {
        counter-increment: list;
        content: counter(list) '.';
        position: absolute;
        left: -$inuit-global-spacing-unit-great;
        width: $inuit-global-spacing-unit-great;
        text-align: center;
      }
    }



    ul li:before {
      content: '•';
      font-size: 1.5em;
      line-height: 1.5em;
    }

    p {
      margin-bottom: $inuit-global-spacing-unit-great;
    }

    .post-type-post & {
      max-width: 670px;
    }
    &-gallery {
      .blocks-gallery-grid {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
      }
      @for $i from 1 through 8 {
        &.columns-#{$i} {
          .blocks-gallery-item {
            flex: 0 0 calc(100% / #{$i});
          }
        }
      }
    }
  }
}
