/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  &__right {
    justify-content: flex-end;
    @media(min-width: 1280px) {
      justify-content: space-between;
    }
  }
  
  &__icon {
    margin-right: 7px;
    span {
      width: 14px;
      height: 2px;
      display: block;
      background-color: color-var(main, 1);
      margin-bottom: 5px;
      transition: 0.2s;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__icons {
    .c-main-nav__link {
      display: flex;
      align-items: center;
    }
  }

  &__menu {
    &--btn {
      font-size: 16px;
      cursor: pointer;
      &.open {
        .menu-text {
          color: color-var(main, 1)!important;
        }
        span:nth-child(3) {
          opacity: 0;
          transform: translateY(5px);
        }
        span:nth-child(1) {
          transform: translateY(7px) rotate(45deg);
        }
        span:nth-child(2) {
          transform:  rotate(-45deg);
        }
      }
    }
  }
  &.mobile {
    flex-direction: column;
    align-items: flex-start;

    .c-main-nav__item {
      a:hover {
        color: color-var(main, 1)!important;
      }
    &:last-child {
      margin-top: 15px;
      margin-bottom: 30px;
      background-color: color-var(main, 1);
      .c-main-nav__link {
        padding-top: 0;
        padding-bottom: 0;
      }
      &:hover {
        background-color: transparent;
        color: #000;
        .c-main-nav__link {
          color: #000!important;
        }
      }
    }
    }
    .c-main-nav__link {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  &__wrap--mobile {
    width: 90vw;
    right: initial;
    left: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    .c-footer__tel {
      transition: 0.2s;
    }
    .c-footer__tel svg path {
      transition: 0.2s;
      fill: #000;
    }
    .c-footer__tel:hover {
      
        color: color-var(main, 1)!important;
      
      &  svg path {
        fill: color-var(main, 1)!important;
      }
    }
    .social {
      .c-main-nav__icons {
        justify-content: center;
        svg path {
          fill: #000;
        }
        svg g {
          opacity: 1;
        }
        svg {
          transition: 0.2s;
        }
        svg:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

.c-main-nav__item {
  list-style: none;
  margin: 0;
  margin-left: $inuit-global-spacing-unit-huge;
  @media (max-width: 1400px) {
    margin-left: 30px;
  }
  @media (max-width: 1310px) {
    margin-left: 25px;
  }
  &--icon {
    margin-left: 15px;
  }

  &:hover .c-main-nav__dropdown {
    display: flex;
  }
}

.c-main-nav__link {
  display: block;
  padding: $inuit-global-spacing-unit-big 0;
  text-decoration: none;
  color: color-var(func, 1);
  transition: 0.2s;

  &:hover {
    color: color-var(font, 4);
  }
}

.current-menu-item .c-main-nav__link {
  color: color-var(font, 4);
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;
  width: 100%;
  background: color-var(bg, 5);
  padding: $inuit-global-spacing-unit-large 0;

  &:before {
    @include absolutePseudoContent();
    bottom: 100%;
    left: 0;
    width: 100%;
    height: $inuit-global-spacing-unit-large;
  }
}

.c-main-nav__list,
.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  display: block;
  padding: 0.5em;
  text-decoration: none;
  color: color-var(font);

  &:hover {
    color: color-var(font, 4);
  }
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: color-var(border);
}
