.c-icons {
  &__title,
  &__subtitle {
    text-align: center;
  }
  &__content {
    margin-top: $inuit-global-spacing-unit-huge;
  }
  &__icon {
    height: $inuit-global-spacing-unit-massive;
    margin-bottom: $inuit-global-spacing-unit-huge;
  }
}
