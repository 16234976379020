/* ==========================================================================
   #tease
   ========================================================================== */

.c-tease {
  $self: &;
  display: block;
  position: relative;
  text-decoration: none !important;
  @include inuit-font-size($inuit-font-size-h5);
  height: 100%;

  .o-link {
    text-decoration: none !important;
  }

  &:hover {
    color: inherit;
  }

  &__title {
    text-align: center;
    margin-bottom: $inuit-global-spacing-unit;
  }

  &__desc {
    padding: $inuit-global-spacing-unit-great $inuit-global-spacing-unit-large;
  }

  &__content {
    @include inuit-font-size($inuit-font-size-h4);
  }

  &__excerpt {
    margin-bottom: 0;
  }

  &__image {
    background-size: cover;
    background-position: center center;
    position: relative;
    display: block;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 59.26%;
    }

    &-elem {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  &--horizontal,
  &--big {
    display: flex;
  }

  &--horizontal {
    #{$self}__desc,
    #{$self}__image {
      flex: 1;
    }
    #{$self}__image {
      max-width: 270px;
    }
    #{$self}__desc {
      padding: 0;
      padding-left: $inuit-global-spacing-unit;
    }
  }

  &--big {
    #{$self}__desc {
      flex: 7;
      padding: $inuit-global-spacing-unit;
      @include inuit-font-size($inuit-font-size-h4);
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;

      &:after {
        content: '';
        flex: 0 0 100%;
      }
    }

    #{$self}__meta,
    #{$self}__content {
      flex: 0 0 100%;
    }

    #{$self}__image {
      flex: 5;
      order: 2;

      &:before {
        padding-bottom: 100%;
      }
    }
  }

  &--promo {
    text-align: center;

    #{$self}__meta {
      justify-content: center;
    }
  }

  &--simple {
    background: transparent;
    @include inuit-font-size($inuit-font-size-h4);
    padding: $inuit-global-spacing-unit-tiny 0;
    border-bottom: $global-border solid color-var(border);

    #{$self}__title {
      margin-bottom: 0;
    }
    &:hover {
      #{$self}__title {
        color: color-var(font, 4);
      }
    }
  }
  &--team {
    padding: $inuit-global-spacing-unit-huge 0;
  }
  &--team .c-tease {
    &__image {
      width: 230px;
      max-width: 100%;
      margin: auto;
      border-radius: 50%;
      &:before {
        padding-bottom: 100%;
      }
    }

    &__desc {
      text-align: center;
    }
    &__additional-info {
      margin-top: $inuit-global-spacing-unit-big;
    }

    &__phone,
    &__email {
      display: block;
      text-align: center;
    }
  }
}
