.c-why {
    background-color: #141414;
    padding-top: 56px;
    padding-bottom: 70px;
    @media(max-width: 800px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    &__cards {
        align-items: normal;
        margin-bottom: 60px;
        @media(max-width: 1024px) {
            margin-bottom: 0;
        }
    }
    &__card {
        background-color: #1B1B1B;
        border-radius: 10px;
        box-shadow: 0 24px 58px 0 rgba(0,0,0,0.56);
        padding: 38px 50px;
        height: 100%;
        &--title {
            color: color-var(main, 1);
            font-size: 40px;
            font-family: $global-heading-font-family;
            margin-bottom: 0;
            text-align-last: left;
            font-weight: 700;
        }   
        &--subtitle {
            font-size: 18px;
            font-weight: 500;
            font-family: $global-heading-font-family;
            color: #E7E7E7;
            text-align-last: left;
            margin-bottom: 0;
        }
        &--wrap {
            @media(max-width: 1024px) {
                margin-bottom: 30px;
            }
        }
    }
    &__content {
        @media (max-width: 768px) {
            text-align: center;
        }
        &--title {
            font-size: 30px;
            @media(max-width: 800px) {
                font-size: 24px;
            }
        }
        ul {
            margin-left: 0;
            padding-left: 32px;
            @media (max-width: 768px) {
                text-align: left;
                width: fit-content;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
        p {
            margin-bottom: 32px;
            @media (max-width: 768px) {
                margin-bottom: 20px;
            }
        }
        li {
            list-style-type: none;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                left: -32px;
                top: 0;
                width: 20px;
                height: 20px;
                background-image: url(../../src/assets/images/svg/checkmark.svg);
                display: block;
            }
        }
    }
    &__row {
        margin-bottom: 37px;
        align-items: center;
        &.first {
            margin-bottom: 77px;
            align-items: center;
            @media (max-width: 768px) {
                margin-bottom: 46px;
            }
            .c-why__img {
                @media (max-width: 768px) {
                    margin-bottom: 30px;
                }
            }
        }
    }
    &__img {
        border-radius: 10px;
        @media (max-width: 1100px) {
            width: 100%;
        }
        &--small {
            max-width: 80%;
            margin-left: auto;
            display: block;
        }
    }
}