.c-voucher {
    padding-top: 175px;
    padding-bottom: 90px;
    background-color: #141414;
    @media (max-width: 768px) {
        padding-top: 100px;
        padding-bottom: 30px;
    }
    &__form {
        max-width: 770px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
    }
    .c-form {
        &__header {
            border-radius: 10px 10px 0 0;
            padding: 40px 31px 40px 35px;
            @media (max-width: 768px) {
                padding: 20px;
            }
        }
        .c-header {
            &__img { 
                max-height: 160px;
                @media (max-width: 768px) {
                    max-height: 100px;
                }
            }
        }
        &__content {
            padding: 60px 100px;
            background-color: #fff;
            border-radius: 0 0 10px 10px;
            @media (max-width: 768px) {
                padding: 30px 20px;
            }
            @media (max-width: 350px) {
                padding: 30px 10px;
            }
        }
        .c-button {
            @media (max-width: 768px) {
                display: block;
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 0!important;
            }
        }
        &__infotext {
            @media (max-width: 768px) {
                text-align: center;
                display: block;
            }
        }

    }
}