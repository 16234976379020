.c-car {
  &__spec {
          margin-top: -159px;
          background: #fff;
          @media (max-width: 768px) {
            margin-top: 0;
          }
  }
  &-info {
    padding-top: 45px;
    padding-bottom: 90px;
    position: relative;
    // z-index: -10;
    z-index: 1;
    @media (max-width: 768px) {
      padding-bottom: 30px;
    }
    &__tab {
      width: initial !important;
      flex: initial !important;
      font-size: 18px;
      font-weight: 700;
      font-family: $global-heading-font-family;
      margin-right: 32px;
      padding-left: 0!important;
      padding-bottom: 36px;
      cursor: pointer;
      &.active {
        box-shadow: inset 0 -4px #000;
      }
    }
    &__tabs {
      padding-top: 36px;
      position: -webkit-sticky;
      position: sticky;
      top: 90px;
      // box-shadow: 0 24px 10px 0 rgba(0,0,0,0.09);
      background-color: #fff;
      z-index: 9;
      @media (max-width: 1023px) {
      box-shadow: 0 24px 10px 0 rgba(0,0,0,0.09);
      margin-left: -16px;
      margin-right: -16px;
      }
      &:after {
        content: '';
        width: calc(100vw);
        height: 100%;
        left: calc(((100vw - (100%/2) - 100% - 11px)/2)*(-1));
        top: 0;
        display: block;
        position: absolute;
        border-color: #fff;
        box-shadow: 0 24px 10px 0 rgba(0,0,0,0.09);
        z-index: 9;
        @media (max-width: 1023px) {
         display: none;
        }
      }

      @media (max-width: 1280px) {
        top: 60px;
      }
    }
    &__title {
        line-height: 1;
        margin-bottom: 15px;
        font-size: 30px;
        margin-bottom: 35px;
        font-family: $global-heading-font-family;
        @media (max-width: 768px) {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 30px;
        }
    }
    &__description {
        margin-bottom: 40px;
    }
    &__li {
        margin-left: -18px!important;
        margin-bottom: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        &:nth-child(odd) {
            background: #F0F0F0;
            border-radius: 10px;
        }
        p {
            margin-bottom: 0;
            @media (max-width: 768px) {
              &:nth-child(even) {
                text-align: right;
                padding-right: 16px;

              }
            }
        }
    }
    &__table {
        margin-bottom: 60px;
        // min-width: 700px;
        @media(max-width: 1024px) {
          min-width: initial;
        }
        &--wrap {
          overflow-x: auto;
          @media(max-width: 768px) {
            margin-left: -16px;
            margin-right: -16px;
          }
        }
    }
    &__gallery {

      &--img {
        border-radius: 10px 10px;
        margin-bottom: 30px;
        box-shadow: 0 24px 58px 0 rgba(0,0,0,0.30);
      }
    }
    &__embed {
      margin-bottom: 45px;
    }
    iframe {
      width: 100%;
    }
    &__buttons {
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    }
    &-card {
          box-shadow: 0 24px 58px 0 rgba(0,0,0,0.09);
          border-radius: 10px;
          background-color: #fff;
          text-align: center;
          padding-bottom: 35px;
          position: relative;
          @media (max-width: 1023px) {
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
          }
          &.offer {
            @media (max-width: 1024px) {
              max-width: 600px;
              margin-left: auto;
              margin-right: auto;
            }
          }

          // height: 100%;
      &__calendar {
        z-index: 10;
        position: relative;
        max-width: 100%;
       @media (max-width: 1023px) {
         z-index: 1;
         max-width: 500px;
         margin-left: auto;
         margin-right: auto;
       }
      }
      &__img {
          border-radius: 10px 10px 0 0;
          width: 100%;
          object-fit: cover;
      }
      &__info {
          padding-top: 15px;
          padding-left: 25px;
          padding-right: 25px;
      }
      &__title {
          font-size: 20px;
          font-weight: 700;
          font-family: $global-heading-font-family;
          color: color-var(func, 5);
          margin-bottom: 15px;
          &--wrap {
            height: 80px;
          }
      }
      &__price {
          color: #BF0500;
          font-family: $global-heading-font-family;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 0;
          &--desc {
              color: #525252;
              font-size: 12px;

          }
      }
      &__link {
        .c-car-card__title {
          transition: color 0.2s;
          &:hover {
            color: #C2E900;
          }
        }
      }
      &__badges {
        position: absolute;
        top: 5px;
        right: 5px;
        display: flex;
        flex-direction: column;
        gap: .5em;
        &-badge {
          background: #C2E900;
          color: #000;
          font-size: 12px;
          padding: 5px;
          border-radius: 30px;
          text-transform: uppercase;
          font-weight: 600;
        }
      }
  }
  &__characteristic {
    width: 100%;
    &--icon {
      width: 24px;
      height: 26px;
      object-fit: contain;
      margin-right: 10px;
    }
    &--text {
      font-size: 14px;
    }
  }
  &--tabs {
    min-width: 700px;
  }
  &__content {
    &--wrap {
      margin-top: -159px;
      @media (max-width: 768px ) {
       margin-top: 0;
      }
    }
  }
}
