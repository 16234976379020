.c-form {
    &__title {
        font-weight: 500;
        margin-bottom: 8px;
        display: block;
        @media (max-width: 1024px) {
            text-align: left;
        }
    }
    &__field {
        border: 1px solid #979797;
        border-radius: 6px;
        background-color: #FFFFFF;
        padding: 14px;
        width: 100%;
        &:focus-visible {
            border: 1px solid color-var(main, 1);
            outline: 0 solid transparent;
        }
    }
    &__infotext {
        font-size: 12px;
        font-family: $global-heading-font-family;
        color: #7E7E7E;
    }
    textarea {
        resize: none;
    }

}