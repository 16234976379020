.c-custom {
    background: #ededed;

    &__heading {
        font-size: 40px;
    }
    strong {
        font-weight: 500;
    }

}