.c-map {
    position: relative;
    &__title {
        @media (max-width: 1024px) {
            text-align: center;
        }
    }
    &__img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
        min-height: 300px;
        &--dark {
            @media (max-width: 1024px) {
                margin-right: auto;
                margin-left: auto;
                display: block;
            }
        }
    }
    &__left {
        padding-right: 68px;
        padding-top: 50px;
        @media (max-width: 1024px) {
            background: #000;     
            padding-right: 0;   
        }
        @media (max-width: 800px) {
            padding-top: 30px;
        }
    }
    &::before {
        content: '';
        top: 0;
        left: 0;
        background-color: #000;
        position: absolute;
        display: block;
        z-index: -1;
        height: 100%;
        width: calc(50% - 68px - 16px);
        @media (max-width: 1024px) {
            display: none;
        }
    }
    &::after {
        content: '';
        top: 0;
        right: 0;
        background-image: url(../../src/assets/images/contact_map.png);
        background-position: left;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        display: block;
        z-index: -1;
        height: 100%;
        width: calc(50% + 68px + 16px);
        @media (max-width: 1024px) {
            display: none;
        }
    }
}