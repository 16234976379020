.lp_pages_list {
    .swiper-pagination-bullet {
        background-color: #808080 !important;
    }
}

.lp_pages_links {
    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        align-items: center;
        justify-content: center;
    }
}