.c-calendar {
    &__wrap {
        border: 1px solid #DDDDDD;
        border-radius: 10px;
        background-color: #fff;
        margin-bottom: 20px;
        padding-left: 27px;
        padding-right: 27px;
    }
    &__content {
        // TODO: switch off/on calendar form  button
        // form button[type=submit] {
        //     display: none;
        // }
        &.collapse {
            display: block;
            max-height: 0;
            overflow: hidden;
            transition: all 0.1s ease; 
            padding-top: 0;
            &.show {
            padding-top: 5px;
              max-height: 1000px;
              transition: all 0.1s ease;
            }
        }
    }
    &__price-wrap {
        &.collapse {
            display: block;
            max-height: 0;
            overflow: hidden;
            transition: all 0.1s ease; 
            padding-top: 0;
            &.show {
            padding-top: 5px;
              max-height: 1000px;
              transition: all 0.1s ease;
            }
        }
    }
    &__toggle {
        text-decoration: underline;
        padding: 22px 22px;
        width: 100%;
        font-size: 14px;
        color: #000;
        border: 0 solid #DDDDDD;
        border-radius: 10px;
        background-color: #fff;
        cursor: pointer;
    }
    &__info {
        &.collapse {
            display: block;
            max-height: 0;
            overflow: hidden;
            transition: all 0.1s ease; 
            padding-top: 0;
            &.show {
            padding-top: 5px;
              max-height: 1000px;
              transition: all 0.1s ease;
            }
        }
    }
    &__block {
        position: sticky;
        top: 248px;
        z-index: 3;
        transform: translateY(-158px);
        @media (max-width: 1279px) {
            top: 60px;
            margin-top: -158px;
            margin-bottom: 158px;
            transform: translateY(0);
        }
    }
    &__sticky {
        top: 91px;
        position: sticky;
        overflow-y: auto;
        max-height: calc(100vh - 91px);
        box-shadow: 35px 4px 58px 0 rgba(0,0,0,0.09);
        z-index: 10;
        @media (max-width: 1279px) {
            top: 60px;
            max-height: calc(100vh - 60px);
        }
        @media (max-width: 1023px) {
            position: relative;
            max-height: initial;
            top: 0;
            overflow-y: initial;
            box-shadow: none;

        }
    }
    &__mobile {
        max-width: 300px;
    }
}