/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: color-var(font, 4);
  }
}
