.c-simple {
  &__desc {
    margin-bottom: $inuit-global-spacing-unit-big;
  }
  &__title {
    margin-bottom: $inuit-global-spacing-unit-small;
  }
  &--alternative {
    .c-simple__image {
      order: 2;
    }
  }
}
