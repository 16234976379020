@import "mixins";

.rcs-calendar-table {
  border-collapse: collapse;

  // test 
  > thead {
    > .rcs-calendar-thead-title {
      > th {
        font-family: sans-serif;
        font-size: 20px;
        font-weight: normal;
        padding-bottom: 20px;
      }
    }

    > .rcs-calendar-thead-controls {
      > th {
        font-family: sans-serif;
        font-size: 12px;
        font-weight: normal;
        padding-bottom: 10px;

        &:first-of-type {
          text-align: left;
        }

        &:nth-child(2) {
          text-align: center;
        }

        &:last-of-type {
          text-align: right;
        }

        > a {
          font-weight: normal;
          text-decoration: none;
          font-size: 12px;
          padding: 5px;
          background: #f6a137;
          color: #fff;
        }
      }
    }

    > .rcs-calendar-thead-days {
      > th {
        font-weight: normal;
        font-family: sans-serif;
        text-align: center;
        font-size: 12px;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }

  > tbody {
    > tr {
      > td {
        border: 1px solid #fff;
        font-family: sans-serif;
        color: #fff;
        width: 65px;
        height: 35px;
        background: #f6a137;
        text-align: center;
        cursor: pointer;
        @include transition(all, 0.5s);

        &:hover {
          background: darken(#f6a137, 15);
        }

        &.rcs-calendar-prev-month {
          background: #dddddd;

          &:hover {
            background: darken(#dddddd, 15);
          }
        }

        &.rcs-calendar-next-month {
          background: #dddddd;

          &:hover {
            background: darken(#dddddd, 15);
          }
        }

        &.rcs-calendar-unavailable {
          background: #aaa;

          &:hover {
            background: darken(#aaa, 15);
          }
        }

        &.rcs-calendar-selected {
          background: darken(#f6a137, 25);
        }
      }
    }
  }

  > tfoot {
    > tr {
      &.rcs-calendar-tfoot-legend {
        > td {
          font-family: sans-serif;
          font-size: 16px;
          font-weight: normal;
          text-align: center;

          > p {
            display: inline-block;

            &:first-of-type {
              margin-right: 20px;

              > span {
                background: #f6a137;
              }
            }

            &:last-of-type {
              > span {
                background: #aaa;
              }
            }

            > span {
              display: inline-block;
              width: 15px;
              vertical-align: middle;
              height: 15px;
              border-radius: 100%;
            }
          }
        }
      }

      &.rcs-calendar-tfoot-summary, &.rcs-calendar-tfoot-summary-min-days-alert {
        display: none;

        > td {
          font-family: sans-serif;
          font-size: 16px;
          font-weight: normal;
        }
      }

      &.rcs-calendar-tfoot-summary-btn {
        display: none;

        > td {
          padding-top: 20px;
          font-family: sans-serif;
          font-size: 16px;
          font-weight: normal;
          text-align: center;

          > form {
            > button {
              background: #f6a137;
              color: #fff;
              cursor: pointer;
              border: 1px solid darken(#f6a137, 10);
              padding: 10px 20px;
              outline: none;
              @include transition(all, 0.5s);

              &:hover {
                background: darken(#f6a137, 10);
              }

              &:active {
                background: darken(#f6a137, 20);
              }
            }
          }
        }
      }
    }
  }
}
