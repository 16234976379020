.c-content {
    position: relative;
    background-color: #141414;
    @media (max-width: 768px) {
        text-align: center;
    }
    &__terms {
        padding-top: 40px;
        padding-bottom: 40px;
        overflow-x: hidden;
        overflow-y: hidden;
        @media (max-width: 800px) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
        &--wrapper {
            padding-top: 32px;
            padding-bottom: 32px;
            @media (max-width: 768px) {
                padding: 0;
            }
        }
    }
    &__title {
        font-size: 30px;
        @media (max-width: 800px) {
            font-size: 24px;
        }
    }
    &__img {
        border-radius: 10px;
        box-shadow: 0 24px 58px 0 rgba(0,0,0,0.30);
        @media (max-width: 1100px) {
            display: block;
            margin: 0 auto;
            max-width: 600px;
        }
        @media (max-width: 768px) {
            width: 100%;
            position: relative;
            z-index: 10;
            border-radius: 0;
        }
        &--vouchers {
            @media (max-width: 1100px) {
                display: block;
                margin: 0 auto;
            }
        }
        &--how {
            
            @media (max-width: 1100px) {
                display: block;
                margin: 0 auto;
                max-width: 600px;
            }
        }

    }
    &.multiply {
        .c-content {
            &__info {
                padding-right: none;
                padding-left: 65px;
                @media (max-width: 1100px) {
                    padding-left: 25px!important;
                }
            }
            &__link {
                font-size: 18px;
                font-weight: 500;
            }
        }

        .c-content__row:nth-child(even) {
            flex-direction: row-reverse;
            .c-content {
                &__info {
                    padding-left: 0!important;
                    padding-right: 65px;
                    @media (max-width: 1100px) {
                        padding-right: 0;
                        padding-left: 25px!important;
                    }
                }
            }
        }
    }
    &__carlist {
        &--row {
            margin-bottom: 0;
            @media (max-width: 1024px) {
                .o-flex__item:first-child .c-content__carlist--item {
                    background-color: #F0F0F0;
                }
            }
            &:nth-child(odd) {
                .c-content__carlist--item {
                    background-color: #F0F0F0;
                    @media (max-width: 1024px) {
                        background-color: transparent;
                    }
                }
            }
        }

        &--item {
            margin-bottom: 0;
            border-radius: 10px;
            padding: 12px 18px;            
        }
    }
    &__decor {
        position: absolute;
        width: 37%;
        top: 10%;
        left: 4%;
        @media (max-width: 768px) {
            width: 100%;
            right: -30%;
            top: 10%;
            left: initial;
        }
    }
    &__buttons {
        @media (max-width: 1100px) {
            justify-content: center;
            margin-bottom: 30px;
        }
        @media (max-width: 768px) {
            flex-direction: column;
            margin-bottom: 10px;
            .c-button {
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
                display: block;
                margin-bottom: 20px;
            }
        }
    }
    &__info {
        ol {
            @media (max-width: 1100px ) {
                text-align: left;
                max-width: 450px;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }

        }
        @media (max-width: 1100px) {
            text-align: center;
            
        }
    }
    &__comparison {
        min-width: 750px;
    }
    &__text {
        background-color: #fff;
        // comment
        &.dark {
            color: #fff!important;
            .c-content__title {
                color: #ededed!important;
            }
        }
    }
}