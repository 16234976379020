.c-seo {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    background-color: #EDEDED;
    font-size: 14px;
    color: #000;
    &__title {
        font-weight: 700;
    }
    &__desc {
        margin-bottom: 0;
    }
}