/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background: #1B1B1B;
  padding-top: $inuit-global-spacing-unit-huge;
  z-index: 5;
  position: relative;
  @media (max-width: 768px) {
    padding-top: 14px;
    padding-bottom: 100px;
  }
  &__callrow {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 65px;
    @media (max-width: 768px) {
      flex-direction: column;  
      margin-bottom: 14px;
    }
  }
  &__tel {
    padding-left: 20px;
    display: flex;
    align-items: center;
    color: color-var(main, 1);
    @media (max-width: 768px) {
      justify-content: center;
      padding-left: 0;
    }
    span {
      padding-left: 5px;
    }
    svg path {
      fill: color-var(main, 1);
    }
  }
  &__tel--wrap {
    @media (max-width: 768px) {
      flex-direction: column;  

    }
  }
  &__tel--info {
    color: rgba(255,255,255, 0.5);
    margin-bottom: 0;
    font-size: 14px;
    line-height: 24px;
    @media (max-width: 768px) {
      margin-bottom: 30px;
      text-align: center;
    }
  }

  &__copyrights {
    background-color: #0B0B0B;
    color: rgba(255,255,255, 0.5);
    padding-top: 35px;
    padding-bottom: 35px;
    &--wrap {
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        .o-flex__item:last-child {
          order: -1;
          margin-bottom: 30px;
        }
      }
    }
    &--link {
      padding-right: 30px;
      @media (max-width: 768px) {
        padding-right: 0;
        margin-bottom: 16px;
      }
    }
    .c-main-nav__link {
      padding-top: 0;
      padding-bottom: 0;
    }
    &--links {
      @media (max-width: 768px) {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 14px;
      }
    }
  }
  // &__nav.bottom {
  //   .menu {
  //     justify-content: flex-start;
  //     &-item {
  //       margin-right: 50px;
  //       @media (max-width: 768px) {
  //         margin-right: 0;
  //       }
  //     }
  //   }
  // }
  .menu {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 75px;
    @media (max-width: 768px) {
      flex-direction: column;
      text-align: center;
      // margin-bottom: 10px;
    }

    &-item {
      transition: all 0.2s linear;
      color: rgba(255,255,255, 0.5);
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 30px;
      padding-right: 5px;
      @media (max-width: 768px) {
        margin-bottom: 20px;
        font-size: 18px;
      }
    }
  }
  &__logo {
    @media (max-width: 768px) {
      max-height: 60px;
      margin-bottom: 14px;
    }
  }
  .c-main-nav__icons {
    @media (max-width: 768px) {
      justify-content: center;
      margin-left: -15px;
    }
  }
  .c-main-nav__link {
    svg g {
      opacity: 1;
    }
  }
  &__wrapper {
    margin-bottom: 75px;
    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }
}
