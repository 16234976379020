.c-widget {
  &__title {
    margin-bottom: 32px;
    position: relative;
    font-weight: 700;
    font-family: $global-heading-font-family;
    line-height: 1.6em;
    z-index: 1;
    font-size: 24px!important;
  }
  &__article {
    margin-bottom: $inuit-global-spacing-unit-great;
  }
}
