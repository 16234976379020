/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-button {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font-family: $global-heading-font-family;
  font-weight: 600;
  min-width: 160px;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: $inuit-global-spacing-unit-micro 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-large;
  transition: $global-transition;
  @include inuit-font-size($inuit-font-size-h4);
  text-decoration: none;
  border: $global-border solid color-var(border);
  border-radius: $global-radius;
  color: color-var(func, 5);
  position: relative;
  transition: 0.2s;
  &__center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }

  .c-main-nav__link {
    padding: 0;
  }

  &:before {
    @include absolutePseudoContent();
    @include absPosition100();
    background: color-var(bg, 8);
    opacity: 0;
    border-radius: $global-radius;
    @include transition();
    pointer-events: none;
  }
  .c-label--file:hover &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    background: transparent;
    color: color-var(main, 1);
    &:before {
      opacity: 0;
    }
  }

  /* Style variants
   ========================================================================== */

  &--primary {
    border-color: color-var(border, 2);
    background-color: color-var(bg, 2);
  }

  &--toggle {
    &:hover {
      background-color: #fff;
    }
    &-white {
      background-color: #fff!important;
    }
  }

  &--secondary {
    border-color: color-var(border, 3);
    background-color: color-var(sub, 1);
    color: color-var(main, 1);
    border-color: color-var(main, 1);
    &:hover {
      color: color-var(func, 5);
      background: color-var(main, 1);
      .c-main-nav__link {
          color: color-var(func, 5);
      }
    }
    .c-main-nav__link {
      padding: 0;
      color: color-var(main, 1);
      &:hover {
        color: color-var(func, 5);
      }
    }
  }

  &--tertiary {
    border-color: color-var(border, 4);
    background-color: color-var(bg, 4);
  }
  &--error {
    border-color: color-var(error);
    background-color: color-var(error);
  }
  &--success {
    border-color: color-var(success);
    background-color: color-var(success);
  }

  &--full {
    width: 100%;
  }

  &--fixed {
    min-width: $inuit-global-baseline * 50;
  }

  &--alternative {
    background-color: color-var(bg, 1);
    color: color-var(font);
    &:before {
      display: none;
    }

    &:hover,
    &:active,
    &:focus {
      color: color-var(font, 3);

      &.c-button--primary {
        color: color-var(bg, 2);
        .c-icon * {
          fill: color-var(bg, 2);
        }
      }

      &.c-button--secondary {
        color: color-var(bg, 3);
        .c-icon * {
          fill: color-var(bg, 3);
        }
      }

      &.c-button--tertiary {
        color: color-var(bg, 4);
        .c-icon * {
          fill: color-var(bg, 4);
        }
      }
      &.c-button--error {
        color: color-var(error);
        .c-icon * {
          fill: color-var(error);
        }
      }
      &.c-button--success {
        color: color-var(success);
        .c-icon * {
          fill: color-var(success);
        }
      }
    }
  }

  &--with-icon {
    position: relative;
    padding-left: $inuit-global-spacing-unit-huge;
    padding-right: $inuit-global-spacing-unit-huge;

    &-right {
      .c-icon {
        left: auto;
        right: $inuit-global-spacing-unit-tiny;
      }
    }

    .c-icon * {
      fill: color-var(font, 3);
    }

    &.c-button--alternative {
      .c-icon * {
        fill: color-var(font);
      }
    }
  }

  /* Size variants
   ========================================================================== */

  &--small {
    padding: 0.25rem 0.5rem;
  }
  &--large {
    padding: 0.75rem 1.5rem;
  }
  &--404 {
    padding-left: 50px;
    padding-right: 50px;
  }
  &--hide-filters {
    padding-left: 65px;
    position: relative;
    &::after {
      content: '';
      width: 22px;
      height: 22px;
      display: block;
      position: absolute;
      left: 25px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url(/wp-content/themes/cylindersi-chisel/src/assets/images/filters.svg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 999 !important;
    }
    @media (max-width: 1100px) {
      margin-left: auto;
      margin-right: auto;
      display: block;
      width: fit-content;
      z-index: 999 !important;
    }
    &--panel {
      display: none;
      @media (max-width: 1100px) {
          display: block;
      }
    }
  }
  &--reservation-wrap {
    display: none;
    @media (max-width: 1023px) {
      display: flex;
      position: fixed;
      bottom: 20px;
      left: 0;
      z-index: 999;
      width: 100%;
    }
  }
}
