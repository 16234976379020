/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();
  margin-right: auto;
  margin-left: auto;
  max-width: 1272px;
  padding: 0 $inuit-global-spacing-unit;
  &__big {
    max-width: 1392px;
  }
  &__small {
    max-width: 1202px;
  }
  &__smallest {
    max-width: 1172px;
  } 
  &__micro {
    max-width: 942px;
  }
  &--rent {
    max-width: 1002px;
  }
  &__comparison {
    overflow-x: auto;
  }
  &__tabs {
    overflow-x: auto;
  }
}