.c-archive {
    position: relative;
    z-index: 10;
    &::before {
        content: '';
        display: block;
        height: 100%;
        width: calc( 400px + (100vw - 1202px)/2 );
        position: absolute;
        right: 0;
        top: 0;
        background-color: #EDEDED;
        z-index: -1;
        @media (max-width: 1202px) {
            width: 34%;
        }
        @media (max-width: 1023px) {
            display: none;
        }
    }
    &__title {
        font-size: 24px !important;
    }
    &__row {
        align-items: initial;
    }
    &__sidebar {
        @media (max-width: 1023px) {
            background-color: #EDEDED;
        }
    }
}