.menu {
  list-style: none;
  padding: 0;
  margin: 0;

  &-item {
    // margin-bottom: $inuit-global-spacing-unit-micro;

    &--icon {
      @include square-style($inuit-global-spacing-unit);
      font-size: 0;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: $inuit-global-spacing-unit;
      margin-bottom: $inuit-global-spacing-unit;
      background-color: color-var(font);
      position: relative;
      display: inline-block;
      margin-right: $inuit-global-spacing-unit-tiny;

      &:hover {
        background-color: color-var(font, 4);
      }

      a {
        position: absolute;
        @include absPosition100;
      }
    }

    &--facebook {
      mask-image: url(../assets/images/svg/facebook.svg);
    }

    &--instagram {
      mask-image: url(../assets/images/svg/instagram.svg);
    }

    &--linkedin {
      mask-image: url(../assets/images/svg/linkedin.svg);
    }

    &--youtube {
      mask-image: url(../assets/images/svg/youtube.svg);
    }
  }
}
