.c-single {
    &__blog {
        h3 {
            font-family: $global-heading-font-family;
            font-size: 24px;
            line-height: 32px;
        }
        .wp-block-image {
            max-width: 100%;
            img {
                width: 100%!important;
                height: auto!important;
                object-fit: contain;
            }
        }
        ul {
            padding-left: 10px;
        }
    }
}