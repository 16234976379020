.c-faq {
    &__topics {
        list-style-type: none;
        border-radius: 10px;
    }
    &__topic {
        padding-top: 18px;
        padding-bottom: 18px;
        transition: all 0.1s ease;
        position: relative;
        border-bottom: 1px solid transparent;
        border-top: 1px solid transparent;
        cursor: pointer;
        &:hover {
            // background-color: rgba(194, 233, 0, 0.1);
            border-bottom: 1px solid #C2E900;
            border-top: 1px solid #C2E900;

        }
        &:before {
            content: '';
            width: 16px;
            height: 16px;
            display: block;
            background-image: url(/wp-content/themes/cylindersi-chisel/src/assets/images/svg/arrow.svg);
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
            opacity: 0;
            visibility: hidden;
        }
        &.active {
            background-color: color-var(main, 1);
            &::before{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &__board {
        list-style-type: none;
        border-radius: 10px;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        @media (max-width: 1024px) {
            margin-left: 0;
        }
        &.active {
            position: relative;
            opacity: 1;
            visibility: visible;
        }
    }
    &__question {
        font-weight: 500;
        border-bottom: 1px solid #D8D8D8;
        position: relative;
        cursor: pointer;
        &:before {
            content: '';
            width: 16px;
            height: 16px;
            display: block;
            background-image: url(/wp-content/themes/cylindersi-chisel/src/assets/images/svg/arrow.svg);
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
        }
        &.opened {
            &::before {
                transform: translateY(-50%) rotate(0deg);
            }
        }
    }
    &__answer {

    }
    .collapse {
        display: block;
        max-height: 0;
        overflow: hidden;
        transition: all 0.1s ease; 
        padding-top: 0;
        &.show {
            padding-top: 24px;
          max-height: 1000px;
          transition: all 0.1s ease;
        }
    }
}