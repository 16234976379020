.wpml-ls-last-item {
  margin-left: 5px !important;
}

.wpml-ls-last-item a:before {
  content: '/';
  font-size: 10px;
  vertical-align: top;
  padding-top: 3px;
  display: inline-block;
  margin-right: 5px !important;
  color: #fff !important;
}

@media (max-width: 768px) {
  .wpml-ls-menu-item {
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
  }

  .c-main-nav {
    text-align: center;
  }

  .wpml-ls-last-item a:before {
    color: #000 !important;
  }
}

.wpml-ls-menu-item a:after {
  display: none !important;
}

.wpml-ls-current-language a,
.wpml-ls-menu-item a:hover {
  color: #6deed8 !important;
}

.c-score__logo {
  top: 10px;
  right: 20px;
}

.c-score__desc {
  min-height: 5em;
}

.u-recapchta-info {
  padding: 15px 0;
  max-width: 300px;
  font-size: 0.6em !important;
  opacity: 0.7;
}

.grecaptcha-badge {
  visibility: hidden;
}

.c-hero__brand {
  display: flex;
  align-items: center;
  padding: 2em 1em;
}

.c-hero__brand,.c-hero__main {
  min-height: 98vh;
}

.c-hero__container--brand {
  width: 1200px;
  max-width: 100%;
  justify-content: flex-start;
}

.c-hero__description {
  color: rgb(255,255,255);
  margin-top: 1em;
  text-align: justify;
}

.c-hero__content--brand {
  flex-direction: column;
  text-align: center;
  max-width: 100%;
  @media (min-width: 800px) {
      max-width: 800px;
  }
  gap: 1em;
}

.c-hero__logo,.c-hero__logo[height] {
  max-width: 100%;
}

.c-hero__description {
  color: rgb(255,255,255);
  margin-top: 1em;
  text-align: justify;
}

@media(min-width: 768px) {
  .c-hero__content--brand {
      align-items:flex-start;
  }

  .c-hero__description {
      text-align: left;
  }

  .c-hero__description {
      text-align: left;
  }

  .c-filters__cards--brand {
      margin-top: -22vh;
  }
}

.c-hero__subtitle,.c-hero__title {
  color: rgb(255,255,255);
  margin-bottom: 0px;
}

.c-hero__title--main {
  font-size: calc(1.5rem+1vw);
}

.c-heading--h1 {
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1.5em;
}

.c-hero__buttons {
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;
  height: fit-content;
}

.c-button--secondary {
  border-color: transparent;
  background-color: transparent;
  color: #C2E900;
  border-color: #C2E900;
}

.c-button--primary {
  border-color: #C2E900;
  background-color: #C2E900;
}

.ti-widget.ti-goog {
  width: 100%!important;
}

.hidden {
  display: none;
}

.wpcf7-list-item.first {
  margin: 0;
}