.c-filters {
    position: relative;
    &__header {
        background-color: #FFFFFF;
        box-shadow: 0 24px 58px 0 rgba(0,0,0,0.09);
        z-index: 15;
        position: relative;
    }
    &__content {
        position: relative;
    }
    &__cars {
        &--number {
            font-size: 24px;
            height: 59px;
            line-height: 59px;
            @media (max-width: 1200px) {
                font-size: 20px;
            }
            @media (max-width: 1100px) {
                line-height: 26px;
                height: 26px;
                margin-bottom: 20px;
            }
        }
    }
    &__sort {
        height: 59px;
        line-height: 59px;
        border: none;
        &:focus-visible {
            border: none;
            outline: none;
        }
        @media (max-width: 1100px) {
            margin-right: auto;
            display: block;
            margin-left: auto;
            line-height: 21px;
            height: 21px;
            margin-bottom: 8px;
        }
    }
    &__title {
        font-size: 24px;
    }
    &__button {
        &--panel {
            width: 100%;
            text-align: left;
            background: transparent;
            border: 0;
            color: #0A1E2C;
            font-size: 18px;
            cursor: pointer;
            &::before {
                content: '';
                width: 16px;
                height: 16px;
                display: block;
                background-image: url(/wp-content/themes/cylindersi-chisel/src/assets/images/arrow.svg);
                position: absolute;
                right: 0;
                top: 50%;

                transform: translateY(-50%) rotate(180deg);
            }
            &.opened {
                &:before {
                    transform: translateY(-50%) rotate(0);
                }
            }
        }
        &--clear {
            border: 0;
            background: transparent;
            color: #4A4A4A;
            opacity: 0.7;
            transition: linear 0.2s;
            cursor: pointer;
            &:hover {
                opacity: 0.9;
            }
        }
        &--close {
            position: relative;
            font-size: 30px;
            width: 30px;
            height: 30px;
            font-weight: bold;
            border: none;
            background-color: transparent;
            cursor: pointer;
            display: none;
            font-family: 'Space Grotesk';
            @media (max-width: 1100px) {
                display: block;
            }
        }
    }
    &__panel {
        padding-bottom: 10px;
        position: relative;
        z-index: 2;
        border-radius: 0 0 10px 0;
        @media (max-width: 1100px) {
            position: fixed;
            left: 10px;
            top: 0;
            bottom: 0;
            width: calc(100% - 20px);
            max-width: calc(100%);
            z-index: 99999;
            overflow-y: auto;
            padding-left: 16px;
            border-radius: 10px!important;
        }

        &::before {
            content: '';
            width: 1000%;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            background: #fff;
            display: block;
            z-index: 0;
            border-radius: 0 0 10px 0;
        }
        &--row {
            border: 1px solid #F1F1F1;
            border-radius: 6px;
            background-color: #FFFFFF;
            padding: 0 12px;
            margin-bottom: 10px;
            position: relative;
            &:last-child {
                margin-bottom: 30px;
            }
            &-count {
            color: #919191;
            }
            &.no-border {
                border: none;
            }
            &.selected {
                a {
                    font-weight: bold;
                }
            }
        }
        &--block {
            border-top: 1px solid rgba(237, 237, 237, 0.7);
        }
        &--lable {
            padding: 16px 0;
            display: block;
            cursor: pointer;
            &.disabled {
                color: grey;
            }
        }
        &--checkbox {
            position: absolute;
            opacity: 0;
        }
        &.hidden {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            max-width: 0 !important;
            max-width: 0 !important;
        }
    }
    .c-checkbox {
        &:checked + .c-filters__panel--lable {
            .c-checkbox__replacement {
                position: relative;
                &:before {
                    content: '';
                    background: url(/wp-content/themes/cylindersi-chisel/src/assets/images/checkmark.svg);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) scale(1.01);
                    width: 100%;
                    height: 100%;
                }
                &:after {
                    display: none;
                }
            }
        }


    }
    .collapse {
        display: block;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease;
        box-sizing: border-box;

        &.show {
          max-height: 10000px;
          transition: max-height 0.2s ease;
        }
    }
    &__toggle {
        &--wrap {
            @media (max-width: 1100px) {
                position: fixed;
                bottom: 30px;
                left: 50%;
                padding-left: 0!important;
                text-align: center;
                transform: translateX(-50%);
                z-index: 99;
            }
        }
    }
    &__cards {
        &.u-flex-2\/3 {
            padding-left: 32px!important;
        }
    }
}