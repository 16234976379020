/* ==========================================================================
   #RADIO BUTTON
   ========================================================================== */

.c-radio {
  @include clickableInput();

  &__replacement {
    border-radius: 50%;

    &:before {
      @include absolutePseudoContent();
      @include square-style($inuit-font-size-h5);
      border-radius: 50%;
      left: 3px;
      top: 3px;
    }
  }
}
