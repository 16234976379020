/* ==========================================================================
   #HEADINGS
   ========================================================================== */

.c-heading {
  position: relative;
  font-weight: $global-heading-font-weight;
  font-family: $global-heading-font-family;
  margin-bottom: $inuit-global-spacing-unit;
  @include inuit-font-size($inuit-font-size-h4);
  z-index: 1;

  &--h0 {
    @include inuit-font-size($inuit-font-size-h0, $inuit-line-height-h0);
  }

  &--h1 {
    @include inuit-font-size($inuit-font-size-h1, $inuit-line-height-h1);
  }

  &--h2 {
    @include inuit-font-size($inuit-font-size-h2, $inuit-font-size-h2);
    @media (max-width: 800px) {
      font-size: 32px;
    }
    
  }

  &--h3 {
    @include inuit-font-size($inuit-font-size-h3, $inuit-line-height-h3);
  }
}
