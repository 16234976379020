.c-process {
    &__card {
        padding: 38px;
        border-radius: 10px;
        box-shadow: 0 24px 58px 0 rgba(0,0,0,0.09);
        position: relative;
        z-index: 2;
        @media (max-width: 768px) {
            margin-bottom: 100px;
        }
        &:before {
            content: attr(data-cardnumber);
            width: 60px;
            height: 60px;
            background-color: color-var(main, 1);
            border-radius: 50%;
            position: absolute;
            right: -115px;
            top: 50%;
            transform: translateY(-50%);
            line-height: 60px;
            font-size: 30px;
            font-weight: 700;
            z-index: 2;
            @media (max-width: 768px) {
                right: initial;
                left: 50%;
                top: -20px;
                transform: translate(-50%, -100%);
            }
        }
        &:after {
            content: '';
            width: 2px;
            height: 70%;
            position: absolute;
            right: -86px;
            top: 50%;
            background-color: #ccc;
            z-index: 0;
            @media (max-width: 768px) {
                height: 100px;
                left: 50%;
                top: 0;
                transform: translateY(-100%);
            }
        }
        &:nth-child(even) {
            transform: translateY(70%);
            z-index: 2;
            @media (max-width: 768px) {
                transform: translateY(0);
            }
            &:before {
                right: initial;
                left: -100px;
                @media (max-width: 768px) {
                    right: initial;
                    left: 50%;
                    top: -20px;
                    transform: translate(-50%, -100%);
                }
            }
            &:after {
                right: initial;
                left: -71px;
                @media (max-width: 768px) {
                    height: 100px;
                    left: 50%;
                    top: 0;
                    transform: translateY(-100%);
                }
            }
            
        }
        &:nth-last-child(1) {
            @media (max-width: 768px) {
                margin-bottom: 0;
            }
            &:after {
                display: none;
                @media (max-width: 768px) {
                    display: block;
                }
            }
        }
        &:first-child {
            &:after {
                @media (max-width: 768px) {
                    height: 20px;
                }
            }
        }
        &--title {
            color: #515151;
        }

    }
    &__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        grid-column-gap: 155px;
        grid-row-gap: 120px;
        @media (max-width: 768px) {
            display: block;
            padding-top: 70px;
        }
    }
    &__info {
        color: #525252;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        font-weight: 500;
        font-size: 18px;
    }
}