.c-vouchers {
    &__card {
        border-radius: 10px;
        &--title {
            line-height: 54px;
            @media (max-width: 768px ) {
               text-align: center;
               line-height: 40px;
            }
        }
        &--img {
            height: 160px;
            @media (max-width: 768px ) {
                height: 100px;
            }
        }
        &--top {
            border-radius: 10px 10px 0 0;
            @media (max-width: 768px ) {
                flex-direction: column;
            }
        }
        &--bottom {
            border-radius: 0 0 10px 10px;
            @media (max-width: 768px ) {
                flex-direction: column;
            }
        }
    }
}