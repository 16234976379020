.c-how {
    padding-top: 56px;
    padding-bottom: 66px;
    background: #EDEDED;
    @media(max-width: 800px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    &__cards {
        margin-bottom: 70px;
        align-items: normal;
        @media(max-width: 800px) {
            margin-bottom: 30px;
        }
        &.desktop {
            @media (max-width: 1200px) {
                display: none;
            }
        }
        &.mobile {
            @media (min-width: 1200px) {
                display: none;
            }
            .c-how__card {
                min-height: 300px;
            }
        }
    }
    &__card {
        background-color: color-var(func, 1);
        border-radius: 10px;
        box-shadow: 0 24px 58px 0 rgba(0,0,0,0.09);
        padding: 36px;
        align-items: center;
        height: 100%;
        &--title {
            margin-bottom: 25px;
            text-align: center;
            color: #515151;
            font-family: $global-heading-font-family;
            font-weight: 700;
            font-size: 20px;
        }
        &--description {
            margin-bottom: 0;
        }
        &--img {
            margin-bottom: 36px;
            max-height: 90px;
            max-width: 90px;
        }
    }
    &__title {
        margin-bottom: 50px;
        @media(max-width: 800px) {
            margin-bottom: 30px;
        }
    }
    &__pagination {
        .swiper-pagination-bullet {
            background-color: Gray!important;
            opacity: 0.3;
            &.swiper-pagination-bullet-active {
                background-color: Gray!important;
                opacity: 1;
            }
        }

    }

}