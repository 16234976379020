.c-searchform {
    width: 500px;
    @media(max-width: 768px) {
        width: 100%;
        flex-direction: column;
    }
    &__form {
        @media(max-width: 768px) {
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    &__input {
        @media(max-width: 768px) {
            width: 100%;
            max-width: 100%;
            margin-bottom: 20px;
        }
    }
    &__btn {
        @media(max-width: 768px) {
            &.c-button--search {
                margin-left: auto!important;
                margin-right: auto;
            }
        }
    }
    &__row {
        @media (max-width: 1024px) {
            justify-content: center;
            align-items: center;
            max-width: 100%;
        }
    }
    &__result {
        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;  
        }
    }
}