.c-documents {
    background-color: #141414;
    &__files {
        border-radius: 10px;
        background-color: #1B1B1B;
        padding: 35px 40px 1px 40px; 
        @media (max-width: 768px) {
            padding: 30px 20px 1px 20px; 
        }
    }
    &__file {
        position: relative;
        &::before {
            content: '';
            width: 40px;
            height: 48px;
            display: block;
            position: absolute;
            background-image: url(/wp-content/themes/cylindersi-chisel/src/assets/images/svg/pdf.svg);
            left: -40px;
            top: 50%;
            transform: translateY(-50%);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        &--title {
            font-size: 18px;
            font-weight: 700;
            line-height: 21px;
        }
        &--link {
        }
    }
    &__title {
        font-size: 30px;
        line-height: 40px;
        @media (max-width: 768px) {
            font-size: 24px;
            line-height: 30px;
        }
    }
    &__info {
        @media (max-width: 768px) {
            // text-align: center;
        }
    }
}