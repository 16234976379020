.c-comparison {
    &--table {
        position: relative;
        z-index: 1;
        &::before {
            content: '';
            position: absolute;
            width: 38%;
            height: calc(100% + 60px);
            top: -32px;
            right: -32px;
            border: 1px solid #C2E900;
            border-radius: 10px;
            box-shadow: 0 24px 58px 0 rgba(0,0,0,0.09);
            z-index: 0;
        }
    }
    &__header {
        background: color-var(main, 1);
        border-radius: 10px;
        padding: 12px 18px;
    }
    &__row {
        border-radius: 10px;
        padding: 12px 18px;
        &:nth-child(odd) {
            background-color: #F0F0F0;
        }
    }
    &__footer {
        border-radius: 10px;
        padding: 12px 18px;
    }
    .red {
        color: #c00505;
    }
}