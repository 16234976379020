.c-contactform {
    position: relative;
    &.full {
        
        &:before {
            display: none;
        }
    }
    &:before {
        content: '';
        height: 100%;
        width: 30%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #ededed;
        z-index: -1;
        @media (max-width: 1024px) {
            display: none;
        }
    }
    &__title {
        font-family: $global-heading-font-family;
        font-size: 30px;
        line-height: 41px;
        font-weight: 500;
        @media (max-width: 1024px) {
            font-size: 24px;
        }
        &--small {
            font-size: 22px;
            line-height: 30px;
            margin-bottom: 15px;
            @media (max-width: 1024px) {
                font-size: 20px;
            }
        }
    }
    &__info {
        padding-top: 50px;
        position: relative;
        @media (max-width: 1024px) {
            text-align: center;
        }
        &:after {
            content: '';
            height: 100%;
            width: 68px;
            position: absolute;
            right: 0;
            top: 0;
            background-color: #fff;
            z-index: 1;
            @media (max-width: 1024px) {
                display: none;
            }
        }
        &.full {
            max-width: 600px;
            &:after {
                display: none;
            }
            &:before {
                display: none;
            }
        }
    }
    &__form {
        padding-top: 50px;
        @media (max-width: 1024px) {
            text-align: center;
        }
        em {
            color: red;
        }
    }
    .wpcf7 .ajax-loader {
        position: absolute;
    }
}