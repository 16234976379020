.c-tag {
  display: inline-block;
  text-transform: lowercase;
  color: color-var(font, 3);
  @include inuit-font-size($inuit-font-size-h6, 1.3334em);
  padding: $inuit-global-spacing-unit-micro $inuit-global-spacing-unit-tiny;
  background: color-var(bg, 8);

  &--alternative {
    color: #000;
    background: #fff;
    border: 1px solid color-var(border);
    border-radius: 2px;
    padding: 11px 15px;
    text-transform: uppercase;
    
  }
  &--wrap {

  }
}



.tagcloud {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-bottom: 25px;
}

.tag-cloud-link {
  font-size: 16px!important;
  transition: 0.2s;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #fff;
  margin-left: 10px;
  margin-bottom: 21px;
  box-shadow: 0 24px 58px 0 rgba(0,0,0,0.09);
}

.tag-link-count {
  color: #919191;
}
