/* ==========================================================================
   #CHECKBOX
   ========================================================================== */

.c-checkbox {
  @include clickableInput();

  &__replacement {
    border-radius: 5px;
    @include checkboxTick();
  }

  &.has-error {
    color: color-var(font, 8);
  }
}
