.c-author {
    background-color: #F1F1F1;
    border-radius: 10px;
    &__title {
        color: #515151;
        font-size: 14px !important;
    }
    &__row {
        align-items: initial;
        @media (max-width: 767px) {
            justify-content: center;
        }
    }
    &__column {
        flex-direction: column;
        justify-content: center;
        flex: 1;
    }


}