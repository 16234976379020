.c-rent {
    &__board {
        border-radius: 10px;
        padding: 35px 100px 50px 100px;
        background-color: #fff;
        @media (max-width: 768px) {
            padding: 30px 10px;
        }
    }
    &__info {
        color: #525252;
    }
}