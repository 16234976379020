.c-categories {
    padding-top: 100px;
    padding-bottom: 70px;
    background-repeat: repeat-y;
    @media (max-width: 1200px) {
        padding-top: 70px;
        padding-bottom: 50px;
    }
    @media (max-width: 800px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    &__title {
        margin-bottom: 180px;
        @media (max-width: 1300px) {
            margin-bottom: 150px;
        }
        @media (max-width: 900px) {
            margin-bottom: 30px;
        }
    }
    &__cards {
        margin-bottom: 72px;
        &.desktop {
            @media (max-width: 900px) {
                display: none!important;
            }
        }
        &.mobile {
            margin-bottom: 30px;
            @media (min-width: 900px ) {
                display: none!important;
            }
            .c-categories__card--wrap {
                transform: translateY(0);
            }
            .c-categories__card {
                height: 380px;
                width: auto;
            }
        }
    }
    &__card {
        $this: &;
        position: relative;
        border-radius: 10px;
        padding: 35px 28px;
        box-sizing: border-box;
        height: 380px;
        width: 570px;
        @media (max-width: 1300px) {
            width: 43vw;
                height: 29vw;
        }
        
        &--wrap {
            border-radius: 10px;
            position: relative;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            display: block;
            &:nth-child(odd) {
                transform: translateY(-100px);
            }
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                border-radius: 10px;
                background-color: rgba(0,0,0, 0.2);
                transition: all 0.2s linear;
            }
            &:hover {
                &::before {
                    background-color: rgba(0,0,0, 0.5);
                }
            }
        }
        

        &:hover {
            #{$this}--content {
                bottom: calc(100% - 35px);
                transform: translateY(100%);
            }
            #{$this}--desc {
                opacity: 1;
            }
            #{$this} {
                &::before {
                    background-color: rgba(0,0,0, 0.5);
                }
            }
            // &::before {
            //     background-color: rgba(0,0,0, 0.5);
            // }
        }
        &--content {
            position: absolute;
            bottom: 35px;
            transform: translateY(0);
            z-index: 10;
            transition: all 0.3s linear;
        }
        &--title {
            font-size: 50px;
            line-height: 68px;
            color: color-var(func, 1 );
            margin-bottom: 7px;
            @media (max-width: 1300px) {
                font-size: 40px;
                line-height: 54px;
            }
        }
        &--subtitle {
            color: color-var(main, 1);
            margin-bottom: 0;
            font-size: 18px;

        }
        &--desc {
            position: absolute;
            bottom: 35px;
            margin-bottom: 0;
            color: #D4D4D4;
            opacity: 0;
            transition: all 0.3s linear;
            padding-right: 20px;
        }
    }
    .grid {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-auto-rows: 1fr;
        grid-column-gap: 100px;
        grid-row-gap: 100px;
        @media (max-width: 1300px) {
            grid-column-gap: 50px;
            grid-row-gap: 50px;
        }
        @media (max-width: 991px) {
            grid-column-gap: 30px;
            grid-row-gap: 30px;
        }
    }
    &__link {
        font-weight: 500;
        transition: 0.2s;
        &.active {
            width: fit-content;
            border-radius: 10px;
            box-shadow: 0 24px 58px 0 rgba(0,0,0,0.09);
            line-height: 1;
            position: relative;
            z-index: 10;
            p {
                position: relative;
                z-index: 10;
            }
            span {
                position: relative;
                z-index: 10;
            }
            svg {
                position: relative;
                z-index: 10;
            }
            &:before {
                content: '';
                width: 100%;
                height: calc(100% + 16px);
                position: absolute;
                top: 50%;
                left: 50%;
                background-color: #fff;
                transform:  translate(-50%, -50%);
                border-radius: 6px;
                z-index: 1;
            }
        }
    }
    &__list {
        flex-direction: column;
        @media (max-width: 1024px) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}