.c-social {
    padding-top: 50px;
    position: relative;
    font-family: $global-heading-font-family;
    z-index: 10;
    background: #FAFAFA;
    overflow-x: hidden;
    @media (max-width: 800px) {
        padding-top: 30px;

    }
    &:before {
        content: '';
        width: calc(400px + (100vw - 1202px)/2);
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: #F1F1F1;
        z-index: -1;
        @media (max-width: 1202px) {
            width: 34%;
        }
        @media (max-width: 1023px) {
            display: none;
        }
    }
    &__title {
        line-height: 3.375rem;
        margin-bottom: 0;
        @media (max-width: 1023px) {
            text-align: center;
            font-size: 32px;
            line-height: 43px;
        }
    }
    &__subtitle {
        color: #6E6E6E;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 40px;
        @media (max-width: 1023px) {
            text-align: center;
            margin-bottom: 30px;
        }
    }
    &__wrap {
        align-items: normal;
        @media (max-width: 1023px) {
            margin-left: 0;
        }
    }
    &__link {
        font-size: 18px;
        // margin-bottom: 40px;
        font-weight: 500;
        transition: all 0.2s linear;
        position: relative;
        bottom: 0;
        @media (max-width: 1023px) {
            text-align: center;
            margin-bottom: 30px;
        }
    }
    &__insta {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 1023px) {
            padding-left: 0!important;
        }
    }
    &__insta--images {
        margin-bottom: 30px;
        padding-right: 100px;
        @media (max-width: 1366px) {
            padding-right: 40px;
        }
        @media (max-width: 1023px) {
            padding-right: 0;
            margin-left: -20px;
            margin-bottom: 10px;
            .o-flex__item {
                padding-left: 20px;
            }
        }
    }
    &__insta--img {
        margin-bottom: 30px;
        width: 201px;
        height: 201px;
        object-fit: cover;
        @media (max-width: 1200px) {
            height: 16vw;
        }
        @media (max-width: 1023px) {
            height: 30vw;
            width: 30vw;
            margin-bottom: 20px;
        }
        @media (max-width: 600px) {
        height: 27vw;
        }
    }
    &__youtube {
        padding-left: 100px!important;
        @media (max-width: 1366px) {
            padding-left: 60px!important;
        }
        @media (max-width: 1023px) {
            padding-left: 0!important;
        }
        &--wrap {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            @media (max-width: 1023px) {
                background-color: #F1F1F1;
                padding-top: 30px;
                &::before {
                    content: '';
                    width: 100vw;
                    left: -15px;
                    height: calc(100% + 30px);
                    background-color: #F1F1F1;
                    position: absolute;
                    top: 0;
                    z-index: -1;
                }
                }
        }
        
    }
    &__video {
        // height: 140px;
        &--info {
            margin-bottom: 30px;
        }
        &--title {
            margin-bottom: 0;
            text-transform: uppercase;
            font-weight: 700;
            max-width: 250px;
            @media (max-width: 1023px) {
                max-width: 320px;
            }
        }
        &--details {
            color: #666;
            font-weight: 300;
            font-size: 13px;
        }
        &--link {
            margin-bottom: 0;
            transition: all 0.2s linear;
            &:hover {
                .c-social__video--wrap::before {
                    opacity: 1;
                }
            }
        }
        &--wrap {
            position: relative;
            max-width: 250px;
            @media (max-width: 1023px) {
                max-width: 100%;
            }
            &::before {
                content: '';
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                position: absolute;
                background-color: rgba(0, 0, 0, 0.2);
                display: block;
                transition: all 0.2s linear;
                opacity: 0;
            }
        }
        &--duration {
            position: absolute;
            bottom: 3px;
            right: 3px;
            margin-bottom: 0;
            font-size: 14px;
            color: #fff;
            background-color: #000;
            font-weight: 500;
            padding: 1px 3px;
            font-family: 'Roboto';
        }
    }
    .js-youtube-videos {
        @media (max-width: 1023px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
    }
}