.c-pagination {
    position: relative;
    @media (max-width: 1023px) {
        margin-top: -32px;
    }
    &__li {
        list-style-type: none;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid color-var(main, 1);
        @media (max-width: 767px) {
            width: 30px;
            height: 30px;
        }
        &:nth-child(1) {
            margin-left: 0!important;
        }
    }
    &__pages {

    }
    &__link {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        text-align: center;
        line-height: 50px;
        transition: 0.2s;
        @media (max-width: 767px) {
            width: 30px;
            height: 30px;
            line-height: 30px;
        }
        &.current {
            background-color: color-var(main, 1);
        }
    }
    &__btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-height: 100%;
        min-height: 100%;
        @media (max-width: 1280px) {
            min-width: initial;
        }
        &--next {
            right: 0;
        }
        &--prev {
            left: 0;
        }
    }
    &__arrow {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

