.c-sort {
    list-style-type: none;
    &__li {
        position: relative;
        &:after {
            content: '';
            box-sizing: border-box;
            height: 6px;
            width: 6px;
            border: 2px solid #000000;
            border-top: 0;
            border-left: 0;
            transform: rotate(44deg);
            position: absolute;
            right: -15px;
            top: 43%;
            display: block;
        }
    }
}