.swiper {
    &-wrapper {
        margin-bottom: 40px;
        height: initial!important;
        // @media (max-width: 768px) {
        //     margin-bottom: 0;
        // }
    }
        &-pagination {
        position: relative!important;
        padding-top: 20px;
            &.testimonial {
                    .swiper-pagination-bullet {
                        background-color: #000!important;
                }
            }
            &-bullet {
                width: 40px!important;
                height: 6px!important;
                border-radius: 5.5px!important;
                background: #C2E900!important;
                opacity: 0.2; 
                &-active {
                    opacity: 1;
                }

            }
    }
}
