/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  $this: &;
  background: color-var(sub, 1);
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  transition: all 0.2s ease 0s;
  @media(max-width: 1280px) {
    background-color: #000;
  }
  &__wrapper {
    transition: all 0.2s ease 0s;
    padding-top: $inuit-global-spacing-unit;
    padding-bottom: $inuit-global-spacing-unit;
    min-height: $inuit-global-spacing-unit-massive;
    position: relative;
    @media(max-width: 1280px) {
      min-height: 70px;
    }
    &> .o-flex {
      @media(max-width: 1280px) {
        justify-content: space-between;
        margin-left: 0;
      }
      & > .o-flex__item {
        @media(max-width: 1280px) {
          width: initial;
          flex: initial;
          padding-left: 0;
          width: 33%;
        }
      }
    }
  }
  &__logo {
    transition: all 0.2s ease 0s;
    max-height: 60px;
    @media(max-width: 1400px) {
      max-height: 40px;
      justify-content: center;
    }
    &--wrap {
      @media(max-width: 1280px) {
        justify-content: center;
      }
    }
  }
  .t-transparent-header & {
    background: transparent;
    color: color-var(font, 3);
  }
  &.scrolled {
    background: rgba(0,0,0, 0.8);
    #{$this}__wrapper {
        padding-top: 10px;
        padding-bottom: 10px;
        min-height: 60px;
    }
    #{$this}__logo {
      max-height: 50px;
      @media (max-width: 1280px) {
        max-height: 40px;
      }
    }
    #{$this}__banner {
      top: calc(100% + 10px);
    }
    #{$this}__search {
      top: calc(100% + 10px);
    }

    .c-main-nav__wrap--mobile {
      top: 100%;
    }
  }
  &__icons {
    line-height: 1;
    cursor: pointer;
  }
  &__hook {
    position: relative;
  }
  &__banner {
    transition: all 0.2s ease 0s;
    position: absolute;
    top: calc(100%);
    right: 0;
    background-color: #fff;
    padding: 20px;
    border-radius: 0 0 10px 10px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2 ease;
    @media(max-width: 1280px) {
      right: -15px;
      width: 100vw;
    }
    &.open {
      opacity: 1;
      visibility: visible;
    }
  }
  &__search {
    transition: all 0.2s ease 0s;
    position: absolute;
    top: calc(100% + 16px);
    right: 0;
    background-color: #fff;
    padding: 20px;
    border-radius: 0 0 10px 10px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2 ease;
    @media(max-width: 1280px) {
      right: -15px;
      width: 100vw;
  }
    &.open {
      opacity: 1;
      visibility: visible;
    }
    &--clear {
      transform: translateX(-39px);
    font-size: 32px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    margin-right: -20px;
    }
  }
  &__space {
    background-color: #000;
    height: 100px;
    @media (max-width: 1280px) {
      height: 71px;
    }
  }
}
