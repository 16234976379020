.c-board {
    margin-bottom: 50px;
    @media (max-width: 768px) {
        margin-bottom: 30px;
    }
    &__img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    &__title {

    }
    &__list {
        padding-left: 60px;
        padding-right: 60px;
        @media (max-width: 768px) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    &__row {
        list-style-type: none;
        position: relative;
        &:last-child {
            @media (max-width: 768px) {
                margin-bottom: 10px !important;
            }
        }
        @media (max-width: 768px) {
            text-align: center;
        }
        &:before {
            content: attr(data-number);
            width: 40px;
            height: 40px;
            background-color: #C2E900;
            border-radius: 50%;
            position: absolute;
            left: -70px;
            top: 50%;
            transform: translateY(-50%);
            line-height: 40px;
            font-size: 20px;
            font-weight: 700;
            text-align: center;
            @media (max-width: 768px) {
                position: relative;
                display: block;
                left: 50%;
                top: -20px;
                transform: translate(-50%, 0);
            }
        }
    }
}