.c-requirements {
    background-color: #f1f1f1;
    border-radius: 10px;
    padding: 22px 32px 32px 32px;
    @media (max-width: 768px) {
        padding: 20px 10px;
    }
    &__title {
        @media (max-width: 768px) {
            text-align: center;
        }
    }
    &__list {
        
    }
    &__row {
        list-style-type: none;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            left: -32px;
            top: 0;
            width: 20px;
            height: 20px;
            background-image: url('../../src/assets/images/checkmark.svg');
            display: block;
        }

    }
}