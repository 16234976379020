.c-newsletter {
  &-section {
    z-index: 5;
    position: relative;
  }

  &__title {
    @media (max-width: 1024px) {
      text-align: center;
      margin-bottom: 22px;
    }
  }
  &__desc {
    @media (max-width: 1024px) {
      text-align: center;
    }
  }
  &__fields {
    text-align: left;
    padding-left: 32px;
    @media (max-width: 1024px) {
      padding-left: 0;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__button {

  }
  .c-input {
    border: 1px solid #979797;
    border-radius: 3px;
  }
  .c-button {
    color: color-var(main, 1);
    background-color: #000;
    border-color: #000;
    transition: all 0.2s linear;
    @media (max-width: 1024px) {
    margin-left: auto;
    display: block;
    margin-right: auto;
    }
    &:hover {
      color: #000;
      background-color: color-var(main, 1);
    }
  }
}
